import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.service';

import { Settings,AppSettings } from 'src/app/app.settings';
@Component({
  selector: 'app-toolbar1',
  templateUrl: './toolbar1.component.html'
})
export class Toolbar1Component implements OnInit {
  public settings: Settings;
  public urlmainpage:string;
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();
  constructor(public appSettings:AppSettings,public appService:AppService) { 
    this.settings = this.appSettings.settings;
  }

  ngOnInit() { 
    this.urlmainpage = window.location.host + window.location.pathname ;

  }

  public sidenavToggle(){
    this.onMenuIconClick.emit();
  }
}